import React from "react";
import Loadable from "@loadable/component";
import Testimonial from "../util/testimonial";
import PageWidth from "../util/pagewidth";
import { graphql, useStaticQuery } from "gatsby";
import Countdown from "react-countdown";
import Img from "gatsby-image";
import BuyButtons from "./buybuttons";
//to avoid slow loading times:
//https://www.gatsbyjs.com/docs/using-client-side-only-packages/
const LoadableGumroadButton = Loadable(() => import("../util/GumroadButton"));

const fullPackagePrice = "36";
const fullPackageOldPrice = "49";

const justTheBookPrice = "18";
const justTheBookOldPrice = "29";

export default function Pricing({ affiliateCode }) {
  const pageQuery = useStaticQuery(graphql`
    query {
      bookCover: file(relativePath: { regex: "/book-3d.png/" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const bookCover = pageQuery.bookCover.childImageSharp;

  return (
    <div className="w-full">
      <div className="w-full pt-16 px-8 ">
        <PageWidth>
          <div className="prose sm:prose lg:prose-lg xl:prose-xl mx-auto relative z-10">
            <h3
              id="testimonials"
              className="text-center text-3xl mb-0 mt-12 font-black"
            >
              More reviews:
            </h3>

            <Testimonial
              author="Nom de Bloom"
              role="Review on Amazon.co.uk"
              key="t0"
            >
              <p>
                <strong>The best book I’ve read on ADHD.</strong>
              </p>
              <p>
                The last section - the advice section - has practical,
                actionable things that can be done to help work with ADHD. Some
                of the language is a bit course, but over all, a great book.
              </p>
            </Testimonial>

            <Testimonial
              author="James Hottensen"
              role="Business Development Manager at Cap Base"
              key="t1"
            >
              <p>
                I just started your book, and{" "}
                <strong>I read 120 pages in one sitting.</strong>
              </p>
              <p>
                So many parts of your book are ringing true...can’t wait to read
                the rest.
              </p>
            </Testimonial>

            <Testimonial
              author="Jake Cordero"
              role="Security Engineer at Facebook"
              key="t2"
            >
              <p>
                Just finished reading your book and wanted to let you know that
                I really enjoyed it
              </p>
              <p>
                <strong>Extremely relatable</strong> as a programmer, and{" "}
                <strong>
                  many of your anecdotes felt like you must have been spying on
                  my life
                </strong>
                .
              </p>
              <p>
                Excited to try out some of the practical strategies you
                suggested and see how they go for me.
              </p>
            </Testimonial>
            <Testimonial author="Bala" role="Review on Amazon.in" key="t3">
              <p>
                <strong>
                  The Most Needed Book for Being Productive with ADHD.
                </strong>
              </p>
              <p>
                So much practical advice and doable things. Author just made it
                easy and reliable source. Love it!!!!
              </p>
            </Testimonial>
            <Testimonial
              author="Fares Chaker"
              role="Journalist from Lebanon"
              key="t4"
            >
              <p>
                ADHD Pro is different.{" "}
                <strong>
                  The examples mentioned by Robert were <em>exactly</em> the
                  same as mine.
                </strong>
              </p>
              <p>
                <strong>I couldn't stop reading.</strong>
              </p>
              <p>I found exactly what I was searching for.</p>
            </Testimonial>
            <Testimonial author="Ellice" role="Review from Amazon.com" key="t5">
              <p>
                <strong>
                  This book is an incredible tool for anyone who wants to work
                  towards understanding and managing their ADHD.
                </strong>
              </p>
              <p>
                The author Robert Merki goes through his personal experience and
                others that he has interviewed. He also included practical and
                real ways to better your approach to situation that occur with
                ADHD.
              </p>
              <p>
                I found this book to be both helpful for my adhd self
                improvement journey and my self acceptance. Just reading/hearing
                that someone has gone through or felt what I have is so
                validating and gave me hope. I recommend this to anyone with
                ADHD or anyone who just wants a better understanding of it.
              </p>
            </Testimonial>
            <Testimonial
              author="Bookworm"
              role="Review from Amazon UK"
              key="t6"
            >
              <p>
                <strong>The most useful book I've read in years.</strong>
              </p>
              <p>
                I've only just got to terms with the fact that the reason for a
                lot of my problems, and for my failure to achieve any of my
                goals is that I suffer from ADHD - and while there are a lot of
                books on the subject of ADHD, there seem to be few that offer
                real solutions.
              </p>
              <p>
                It's an encouraging read for a person like me who is ambitious,
                and not happy to just accept that having ADHD means your
                possibilities are limited. Further, it doesn't make wild claims
                (like, "it's a superpower"), but refers to solid research.
              </p>
              <p>
                I felt utterly overwhelmed by how to manage my condition - this
                book broke it down for me into manageable steps. It's not
                necessarily easy, but doable. Even for people who can't hire a
                personal assistant to run their lives.
              </p>
              <p>
                I liked the writing style as well - entertaining, yet clear and
                concise. (Which us essential for readers like me, with no
                patience...)
              </p>
            </Testimonial>
          </div>
        </PageWidth>
      </div>
      <div className="relative z-30 bg-white w-full pb-16 px-4 sm:px-8 ">
        <h3 id="buy" className="text-center text-3xl mb-0 mt-20 font-black">
          Buy the Book
        </h3>
        <h4 className="text-center">
          Join the 1,000+ readers who have upgraded their strategy and overcome
          their ADHD.
        </h4>

        <BuyButtons clickId="Pricing" />

        {/* <div className="py-20 flex flex-wrap md:flex-nowrap flex-col-reverse md:flex-row items-center justify-center">
        <JustTheBook affiliateCode={affiliateCode} bookCover={bookCover}/>
        <FullPackage affiliateCode={affiliateCode} bookCover={bookCover}/>
      </div> */}
        {/* <div className="text-center ">
        <span className="block font-bold">Productivity coaching now available.</span>
        <span className="block">Spots are limited and by application only.</span>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScv8Lh4zfhpWRQt1e_WCbAM59pAPxASQqFPSulxggPwDyGylA/viewform?usp=sf_link" target="_blank">
          <span className="inline-block py-2 px-4 mt-2 bg-black rounded-lg text-white">
            Apply Now
          </span>
        </a>
        <span className="block mt-2">(2 spots left)</span>
      </div> */}
      </div>
    </div>
  );
}

const Feature = ({ children }) => (
  <li className="flex items-center text-xl py-2">
    <div className="flex-shrink-0">
      <svg
        className="mr-2 block text-green-600"
        style={{ width: "20px", height: "20px" }}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
      >
        <path
          className="fill-current"
          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"
        />
      </svg>
    </div>

    <div>{children}</div>
  </li>
);

const JustTheBook = ({ affiliateCode, bookCover }) => (
  <div className="bg-white w-full sm:w-auto rounded-lg rounded-r-none overflow-hidden shadow-md">
    <div className="bg-blueGray-200 p-8 py-4">
      <h4 className="font-bold text-3xl text-deepblue-900 text-center">
        The Book
      </h4>
    </div>

    <div className="p-4 pb-8">
      <div className="text-center flex items-center justify-center">
        <span className="text-blueGray-600 font-bold text-xl        text-center line-through">
          ${justTheBookOldPrice}
        </span>
        <span className="text-green-600 font-bold text-6xl px-2  text-center">
          ${justTheBookPrice}
        </span>
        <span className="text-blueGray-900 font-bold text-md        text-center">
          USD
        </span>
      </div>

      <Countdown date={"2021-04-24T01:02:03"} renderer={CounterRenderer} />
      <ul className="mb-10 px-4">
        <Feature>
          The <strong>235 page e-book</strong>
        </Feature>
        <Feature>
          <strong>PDF</strong>, <strong>Mobi</strong>, and <strong>EPUB</strong>{" "}
          formats
        </Feature>
        {/* <Feature>Works with Apple Books &amp; Google Play Books</Feature>
        <Feature>Compatible with any Kindle or Kobo e-reader</Feature> */}
      </ul>
      <LoadableGumroadButton
        // href="https://gum.co/jjUJS"
        productCode="jjUJS"
        affiliateCode={affiliateCode}
        className="
        text-center
        border
        border-transparent
        text-sm
        shadow-sm
        block
        focus:outline-none
        text-deepblue-700
        text-shadow
        uppercase
        tracking-wide
        font-semibold
        px-8
        py-3
        lg:py-3
        bg-blueGray-400
        hover:bg-blueGray-500
        mx-auto
        block
        rounded
        w-3/4"
      >
        Buy Now
      </LoadableGumroadButton>
    </div>
  </div>
);

const FullPackage = ({ affiliateCode, bookCover }) => (
  <div className="bg-white w-full sm:w-auto rounded-lg overflow-hidden shadow-xl mb-20 md:mb-0">
    <div
      className="bg-blueGray-200 p-8 py-4"
      style={{
        background: "linear-gradient(45deg,rgb(46,92,198),rgb(0,54,179)",
      }}
    >
      <h4 className="font-bold text-3xl text-white text-center">
        The Complete Package
      </h4>
    </div>

    <Img
      fixed={bookCover.fixed}
      loading="eager"
      alt="ADHD Pro Cover"
      className="mx-auto max-w-[200px] block"
      style={{ display: "block" }}
      // style="display:block;"
      // imgStyle="display:block;"
    />
    <FiveStars />

    <div className="px-2 sm:px-8 pt-2 pb-10">
      <div className="text-center flex items-center justify-center">
        <span className="text-blueGray-600 font-bold text-xl        text-center line-through">
          ${fullPackageOldPrice}
        </span>
        <span className="text-green-600 font-bold text-6xl px-2  text-center">
          ${fullPackagePrice}
        </span>
        <span className="text-blueGray-900 font-bold text-md        text-center">
          USD
        </span>
      </div>

      <Countdown date={"2021-04-24T01:02:03"} renderer={CounterRenderer} />

      <ul className="mb-10 px-2 sm:px-4">
        <Feature>
          The <strong>235 page e-book</strong>
        </Feature>
        <Feature>
          <strong>PDF</strong>, <strong>Mobi</strong>, and <strong>EPUB</strong>{" "}
          formats
        </Feature>
        {/* <Feature>Works with Apple Books &amp; Google Play Books</Feature>
        <Feature>Compatible with any Kindle or Kobo e-reader</Feature> */}
        <Feature>
          5+ hours of <strong>companion screencasts</strong> including:
        </Feature>
        <Feature>
          All 3 <strong>overview videos</strong>
        </Feature>
        <Feature>
          All 5 <strong>strategy component videos</strong>
        </Feature>
        <Feature>
          All 3 <strong>expanded planning examples</strong>
        </Feature>
      </ul>
      <LoadableGumroadButton
        // href="https://gum.co/xXXmH"
        productCode="xXXmH"
        affiliateCode={affiliateCode}
        className="
            text-center
            border
            border-transparent
            text-lg
            shadow-sm
            block
            focus:outline-none
            text-white
            text-shadow
            uppercase
            tracking-wide
            font-semibold
            px-10
            py-4
            bg-deepblue-400
            hover:bg-deepblue-500
            mx-auto
            block
            rounded
            w-3/4"
      >
        Buy Now
      </LoadableGumroadButton>
    </div>
  </div>
);

const CounterRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <div></div>;
  }

  const daysText = days === 1 ? "day" : "days";
  const hoursText = hours === 1 ? "hour" : "hours";
  const minutesText = minutes === 1 ? "minute" : "minutes";
  const secondsText = seconds === 1 ? "second" : "seconds";

  return (
    <div className="text-center text-green-600 py-2 text-sm">
      <strong>Spring sale ends in:</strong>
      <br />
      <span>
        {days} {daysText}, {hours} {hoursText}, {minutes} {minutesText}, and{" "}
        {seconds} {secondsText}
      </span>
    </div>
  );
};

const FiveStars = () => (
  <div className="-mt-3 mb-4">
    <div className="text-center text-goodyellow">
      <span className="mr-3"></span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span className="text-sm ml-1 text-yellow-500">(55)</span>
    </div>
    <div className="text-center text-sm text-blueGray-500">
      Rated <strong>4.98</strong> on GoodReads &amp; Amazon
    </div>
  </div>
);

import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

// import PageWidth from "../util/pagewidth";
import TopNav from "../util/TopNav";

function Layout({ children, noHeader=false, homepage=false }) {
  return (
    <div className="flex flex-col font-sans text-blueGray-900 anti-aliased min-h-screen">
      {!noHeader && <TopNav />}

      <main className={`flex w-full flex-wrap ${homepage ? 'homepage' : ''}`}>
        {children}
      </main>
      
      <footer className="text-center bg-blueGray-900 text-blueGray-400 py-12 mt-auto relative z-30">
        <div className="mb-6">
          <Link className="text-blueGray-200 hover:text-white font-bold" to="/">Get the Book</Link>
          <span className="text-blueGray-500 px-4">&bull;</span>
          <Link className="text-blueGray-200 hover:text-white font-bold" to="/blog/">Read the Blog</Link>
          <span className="text-blueGray-500 px-4">&bull;</span>
          <Link className="text-blueGray-200 hover:text-white font-bold" to="/newsletter/">Join the Newsletter</Link>
        </div>
        {/* <div>
          Made by <a href="https://twitter.com/robmerki" target="_blank" rel="noreferrer noopener" className="text-blueGray-200 hover:text-white">Robert Merki</a> in Vancouver, Canada
        </div> */}

        <div className="mt-4 text-sm text-blueGray-500">
        This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
        </div>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

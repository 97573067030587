import React from 'react'
import Layout from "../components/util/layout";
import PageWidth from "../components/util/pagewidth";

export default function samplechapter() {
  return (
    <Layout>
      <PageWidth>
      
      <article className="prose-lg max-w-prose mx-auto pt-10 pb-20">
        <h1>Chapter 18 - Mental Effort</h1>
        <h2>Distractions &amp; ADHD</h2>
        <p>
        What happens when you have a difficult time starting and/or completing a creative task? Something that isn't a simple chore, like writing and essay or working on a project. There is something misconfigured with the stimulation your brain expects to receive.
        </p>
        <p>
        Do you do the logical thing and think about your situation objectively? No, you do what any well-intentioned ADHDer would do. You blame external distractions.
        </p>
        <p>
        You turn your phone to airplane mode. You close all your apps. You turn off your web browser. You install a “blocker” that removes your ability to go on distracting websites. You uninstall your video games. You remove all possible “distracting” objects from your environment. Oh, and don’t forget the part where you internalize the idea that your distractions are a moral failure and a “lack of effort.” You don’t have what it takes to write this essay. You’re lazy! You lack work-ethic! And so on.
        </p>
        <p>
        You sit there trying to Force Yourself to do your work. It might be an essay, it might be some programming task for your job, it might be some spreadsheet. You just can’t do it! Perhaps after a few hours, the stress and anxiety come around to become a pseudo-stimulant, and you produce something mediocre. You can feel it in your gut. Another C level essay rather than the A+ you know you could compose if only you <em>weren’t so lazy</em>.
        </p>
        <p>
        This is the core issue with creative tasks. If it were something that was simply “not fun,” like doing laundry, everything makes sense. Doing the laundry sucks, but it will be done once the clothes are folded. You can measure it. You can physically see how close you are to completion. You don’t even need to be motivated to do laundry. It makes sense that it’s boring. You might even listen to some music while doing it. You never have to “remove distractions” or delete your video games just to do the laundry. The distractions are clearly not at fault. The monotonous task is.
        </p>
        <p>
        When you’re doing something where you really do care about excellence, as with any creative task, you fall into a cycle of stress and anxiety. Your output might qualify as “finished” at some point, seconds before the due date, but the result is a function of frustration and loathing rather than healthy, diligent excellence.
        </p>
        <p>
        Furthermore, people tend to think of distractions as an analog of laziness. But are they really? People with ADHD don’t lazily get distracted. We go all in! This is because we get stimulated by this new activity.
        </p>
        <blockquote className="border-l-4">
        "If I get distracted, I end up putting 200% 
        effort into it."
        <br/>
        — Shirinnada
        </blockquote>
        <p>
        My friend Shirinnada explained this so well. When distractions emerge, they often inspire brand new initiatives or novel ideas that push existing work in the right direction. Her supremely creative mind was craving a fresh new approach that could connect the dots in a more interesting way. Does this sound “lazy” to you? Her distractions aren’t evil, nor did they ultimately affect her work negatively. They’re just stimulating ideas fighting for her attention. If she always Forced Herself to ignore these distractions, she’d have fewer great ideas and a lot less energy.
        </p>
        <p>
        Forcing Yourself is a misallocation of effort. It works in infrequent circumstances and should be avoided at all costs. If you are Forcing Yourself repeatedly because of distractions, you should analyze why your current work is not stimulating you. Forcing Yourself away from them is not a good idea. It’s the sign of a poor mindset to believe in this tactic. Distractions are a flag that something in your process isn’t working.
        </p>
        
        <h2>Work Ethic</h2>
        <p>
        The hardest I ever worked in my life was at a cozy summer internship at a local tech company. They were looking for a summer intern to build a gigantic spreadsheet, and I was luckily hired.
        </p>
        <p>
        The task was to manually go to 500 different websites and rate them on 40 different technical factors. The spreadsheet had 20,000 empty cells that I needed to fill manually. I had 4 weeks to complete this task, so if you do the math, I had to fill 1,000 cells every single day.
        </p>
        <p>
        To be quite honest, it was not technically challenging. I had to check various factors of each webpage in a way that let me turn my brain off. I listened to music all day while doing this work. On paper, it sounds pretty easy, right?
        </p>
        <p>
        It was my first real “office” job, and I brought with me the “look busy, or you’re fired” mindset that many of my high school jobs forced down my throat. I was terrified of getting distracted. I remember looking at my co-worker who dared to check Twitter a few times a day, waiting for him to get reprimanded by his manager. In hindsight, this was silly, but at the time, I was so scared of being unproductive for even a single minute because “they” would notice.
        </p>
        <p>
        During the first week of working there, my brisk intern enthusiasm that pushed me to fill my quota of spreadsheet cells much quicker than I had previously calculated. I pushed more and more and had done a few thousand extra cells by the end of the first week. “This is great!” I thought.
        </p>
        <p>
        It was during the second week of work that I started to slow down. I was getting unstimulated by the monotonous task I was doing, but I was also terrified of being bored. I spent so much mental energy Forcing Myself to stay focused on this task. I refused to go on any website or check Twitter or look at my phone. I was not going to be “bad” and get distracted.
        </p>
        <p>
        All of this energy expenditure drained me completely. I started listening to sad music all day. I was on the verge of tears multiple times. I just blamed allergies and kept pushing.
        </p>
        <p>
        Fortunately, I stuck to my quota and finished the task right on time. I think my manager was impressed. I don’t think they really expected me to finish it within the allotted time. They were kind enough to give me more work with the marketing team, which completely changed my mood. There were many things to do, and I started learning at a fast pace. My sadness was gone. My productivity skyrocketed, as did my mood.
        </p>
        <p>
        I still think about that job from time to time, and it makes me sad. Despite this being a “chill” task, I was suffering all day. If that task didn’t have such a definitive end, I don’t know how I would have continued.
        </p>
        <p>
        This was by far the most “work ethic” I’ve ever put into anything in my life. I spent so much of my mental energy forcing myself to stay “on task” with that spreadsheet. Was it worth it? I did avoid distractions, didn’t I? Is that an ideal that ADHDers should strive for? Was this a good mindset?
        </p>
        <p>
        It’s clear to me now that this ridiculous meme of “work ethic” is just some bullshit trope created by smug executives and belittling politicians as a way to dismiss inequality as a moral issue. Everyone in that office was distracted from time to time. Even the most neurotypical person on Earth can’t focus for 8 hours straight.
        </p>
        <p>
        I’m not saying that succumbing to unproductive or unhealthy cravings is better than expending effort. Still, there is clearly a point where spending high amounts of mental energy solely on the act of staying in place is a signal that you’re somewhere you don’t want to be.
        </p>
        <p>
        I have no negative feelings toward my employer. They had no idea how difficult that task was for me, and they gave me a fantastic first “real job” experience. It was my own mindset that was at fault. Instead of taking breaks or allowing myself a few distractions here and there, I Forced Myself to maintain the illusion of productivity.
        </p>
        <p>
        There is this idea that all productivity comes with the additional cognitive overhead of “work ethic.” That’s not true, and it’s a part of a bad mindset. 
        </p>

        <div className="text-center font-bold mb-2">Want to read more?</div>
        <div className="text-center">
        <a className="
                  text-center
                  mx-auto
                  border
                  border-transparent
                  text-lg
                  shadow-md
                  mx-auto
                  sm:mx-0
                  focus:outline-none
                  text-white
                  text-shadow
                  
                  tracking-wide
                  font-semibold
                  px-6
                  py-3
                  bg-green-600
                  hover:bg-green-500
                  block
                  md:inline-block
                  rounded" href="/#buy">
                {/* <NiceUnderline className="text-deepblue-500"> */}
                  Buy the Full Book
                {/* </NiceUnderline> */}
              </a>
          </div>
      </article>
      </PageWidth>
    </Layout>
  )
}

import React from 'react'

export default function Testimonial({children, author, role, tweet}) {

    // if (tweet) {

    //     return (
    //         <div>
                
    //         </div>
    //     )
    
    // } else {

    

        const AuthorRole = () => role ? <span className="">, {role}</span> : <span></span>;



        return (
            <div className="testimonial bg-deepblue-100 py-4 px-6 my-8 font-extralight border-l-4 border-deepblue-600 shadow-md text-xl max-w-prose mx-auto">
                <div className="text-6xl text-deepblue-300 h-6">
                    &ldquo; 
                </div>
                <div className="mb-2 mt-0">
                    {children}
                </div>
                <div className="text-sm font-normal author">
                    — <span className="">{author}</span><AuthorRole/>
                </div>
            </div>
        )
    // }
}

import React from 'react'
import { Link } from "gatsby";
import BlogpostSuggestedArticle from './suggested-article';
import BlogpostBookPromo from './book-promo';

export default function BlogpostFooter({pageContext}) {
  return (
    <div className="relative z-30">
      <footer className="text-sm font-medium leading-5 divide-y divide-blueGray-200 xl:col-start-1 xl:row-start-2 sm:sticky top-0">
        <div className="space-y-8 py-8">
          <BlogpostBookPromo isAmp={pageContext.isAmp} />
        </div>
        <div className="space-y-8 py-8">
          <BlogpostSuggestedArticle pageContext={pageContext} />
        </div>
        <div className="pt-8 text-lg font-semibold">
          <Link to="/blog/" className="text-deepblue-400 hover:text-deepblue-500">
            &larr; Back to blog
          </Link>
        </div>
      </footer>
    </div>
  )
}

import React from 'react'
import PageWidth from '../util/pagewidth'
import facebook from '../../../static/img/logos/facebook.svg';
import microsoft from '../../../static/img/logos/microsoft.svg';
import google from '../../../static/img/logos/google.svg';
import disney from '../../../static/img/logos/disney.svg';
import netflix from '../../../static/img/logos/netflix.svg';
import verizon from '../../../static/img/logos/verizon.svg';

export default function LogoSection() {
  return (
    <div className="bg-white w-full">
      <PageWidth>
        <div className="py-6">
          <h3 className="text-blueGray-500 text-center">
            Readers of ADHD Pro work at places like:
          </h3>
          <div className="py-4 flex flex-wrap justify-between items-baseline">

            <img className="logo w-32 px-1 py-1" src={microsoft} alt="Microsoft Logo" />
            <img className="logo w-32 px-1 pb-2" src={facebook}  alt="Facebook Logo" />
            <img className="logo-invert h-10 px-1 pt-2" src={disney}  alt="Disney Logo" />
            <img className="logo w-24 px-1 py-1" src={netflix}  alt="Netflix Logo" />
            <img className="logo h-10 px-1 pt-2 pb-0" src={google}  alt="Google Logo" />
            <img className="logo-invert w-32 px-1 py-1" src={verizon}  alt="Verizon Logo" />
          </div>
          
        </div>
      </PageWidth>
    </div>
  )
}

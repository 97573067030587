import React from 'react'
import { Link } from "gatsby";
import PageWidth from '../components/util/pagewidth'
import NiceUnderline from '../components/util/niceunderline';
import NewsletterForm from '../components/util/NewsletterForm';
import SEO from "../components/util/seo";


export default function Newsletter() {
  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-deepblue-700 to-deepblue-900">
      <SEO
        title="Get the Newsletter — ADHD Pro"
        description="Sign-up for the ADHD Pro Newsletter."
        customUrl="newsletter"
      />
      <PageWidth>
        <div className="flex h-full items-center justify-center self-stretch">
          <div className="px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-8 lg:px-8 xl:max-w-6xl">

            <h1 className="font-bold text-xl text-white mb-4">
                <Link to="/" className="">ADHD <span className="text-goodyellow">Pro</span></Link>
            </h1>

            <h2 className="text-white mb-2 text-4xl font-bold">
              ADHD Pro Newsletter
            </h2>
            <p className="text-white mb-2">
              I write about ADHD, productivity, and how to deal with burn-out.
            </p>
            <NewsletterForm noDescription noLine/>

            <Link to="/blog/" className="block mt-10">
              <NiceUnderline className="text-goodyellow">
                &larr; Back to the Blog
              </NiceUnderline>
            </Link>


          </div>
        </div>
      </PageWidth>
    </div>
  )
}

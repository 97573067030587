import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../components/util/layout";
import SEO from "../components/util/seo";
import PageWidth from "../components/util/pagewidth";

function Blog({data}) {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO
        title="Blog — ADHD Pro"
        description="Professional advice about succeeding and thriving with ADHD in a distracting and confusing world."
        customUrl="blog"
      />
      


      <PageWidth>
        <div className="divide-y divide-blueGray-200 mt-16">
          <div className="text-4xl lg:text-5xl xl:text-6xl font-bold">
            <h1 className="leading-14">
              Latest Articles
            </h1>
          </div>

          <ul className="divide-y divide-blueGray-200">
          {posts.map(({node}, index) => {
            const title = node.frontmatter.title;
            const path = node.frontmatter.path;
            const excerpt = node.excerpt;
            return (
              <li className="py-10" key={index}>
                
                <div className="prose sm:prose lg:prose-lg xl:prose-xl mb-5">
                  <h2><Link to={`/blog/${path}/`}>{title}</Link></h2>
                  <p>
                    {excerpt}
                  </p>
                </div>

                <Link className="text-deepblue-500 hover:text-deepblue-700 font-semibold" to={`/blog/${path}/`}>
                  <span className="">Read more &rarr;</span>  
                </Link>

              </li>
            )
          })}
          </ul>
        </div>
      </PageWidth>
    </Layout>
  );
}

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            path
          }
        }
      }
    }
  }
`

import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

function SEO({ description, lang, meta, keywords, title, blogPostPath, customUrl, postType, frontmatter, articleBody, isAmp }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  let blogPostPathFinal = blogPostPath;
  
  if (isAmp) {
    blogPostPathFinal = blogPostPath.replace("amp/", "");
  }

  const metaDescription = description || site.siteMetadata.description;
  const baseUrl = "https://adhdpro.xyz";
  const imagePath = blogPostPathFinal ? `${baseUrl}/og-image/blog/${blogPostPathFinal}.png` : `${baseUrl}/img/ogimage3.png`;
  const twitterImagePath = blogPostPathFinal ? `${baseUrl}/og-image/blog/${blogPostPathFinal}.png` : `${baseUrl}/img/twitterimagewhite.png`;

  const thisUrl = () => {
    if (isAmp) {
      return `${baseUrl}/blog/${blogPostPathFinal}/`;  
    }
    
    if (customUrl) {
      return `${baseUrl}/${customUrl}/`;
    }
    
    if (blogPostPathFinal) {
      return `${baseUrl}/blog/${blogPostPathFinal}/`;
    }
    
    return `${baseUrl}/`;
  };

  const Canonical = () => {

    if (isAmp) {
      return null;
    }
    
    if (customUrl) {
      return <link rel="canonical" href={`${baseUrl}/${customUrl}/`} />;
    }
    
    if (blogPostPathFinal) {
      return <link rel="canonical" href={`${baseUrl}/blog/${blogPostPathFinal}/`} />;
    }
    
    return <link rel="canonical" href={`${baseUrl}/`} />;
    
  };


  // For rich content on Google
  // https://stackoverflow.com/questions/58808915/json-ld-schema-with-gatsbyjs-for-rich-snippets
  let schemaType = blogPostPath ? "blogpost" : postType;
  let schemaJson;
  if (schemaType === "howto") {
    schemaJson = {
      // Your howto schema here
    }
  }

  if (schemaType === "blogpost") {

    schemaJson = {
        '@context': 'http://schema.org',
        '@type': 'Article',
        author: {
          '@type': 'Person',
          name: "Robert Merki",
        },
        copyrightHolder: {
          '@type': 'Person',
          name: "Robert Merki",
        },
        copyrightYear: '2021',
        creator: {
          '@type': 'Person',
          name: "Robert Merki",
        },
        publisher: {
          '@type': 'Organization',
          name: "ADHD Pro",
          logo: {
            '@type': 'ImageObject',
            url: `${baseUrl}/img/twitterimage3.png`,
          },
        },
        articleBody: articleBody,
        datePublished: frontmatter.date,
        dateModified: frontmatter.date,
        description: frontmatter.description,
        headline: frontmatter.title,
        inLanguage: "en",
        url: `${baseUrl}/blog/${blogPostPathFinal}/`,
        name: frontmatter.title,
        image: {
          '@type': 'ImageObject',
          url: imagePath,
        },
        mainEntityOfPage: `${baseUrl}/blog/${blogPostPathFinal}/`,
      }
    }  

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: thisUrl(),
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imagePath,
        },
        {
          property: `twitter:image`,
          content: twitterImagePath,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: "@robmerki",
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
      title={title}
      titleTemplate={title}
    >
      {schemaType === "howto" && (
      <script type="application/ld+json">
       {JSON.stringify(schemaJson)}
      </script>
     )}
     {schemaType === "blogpost" && (
      <script type="application/ld+json">
       {JSON.stringify(schemaJson)}
      </script>
     )}

      {Canonical()}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;

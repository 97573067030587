import React from "react";

import SEO from "../components/util/seo";
import Layout from "../components/util/layout";
import Hero from "../components/landingpage/hero";
import PromoBody from '../components/landingpage/promobody';
import Pricing from '../components/landingpage/pricing';
import VideoPreview from '../components/landingpage/videopreview'
import BookPreview from '../components/landingpage/bookpreview'
import Faq from '../components/landingpage/faq';
import Nav from '../components/landingpage/nav';
import TopNav from '../components/util/TopNav';
import LogoSection from '../components/landingpage/logosection';

function IndexPage({location}) {
  
  const affiliateCode = getAffiliateCode(location);

  return (
    <Layout noHeader homepage>
      <SEO
        title="ADHD Pro - Increase Productivity with ADHD"
        keywords={[`ADHD Pro`, `Adult ADHD Book`, `Happy And Sustainable Productivity For People With ADHD`, `Take control of your ADHD`]}
      />
      <TopNav/>
      <FloatingArrow/>
      <Hero/>
      <LogoSection/>
      <PromoBody />
      <BookPreview affiliateCode={affiliateCode} />
      {/* <VideoPreview /> */}
      <Pricing affiliateCode={affiliateCode} />
      <Faq />

    </Layout>
  );
}

export default IndexPage;

function getAffiliateCode(location) {
  // An affiliate code should look like https://adhdpro.xyz/?a=123456 where 123456 is the affiliate code for gumroad
  if (location && location.href) {
    const params = (new URL(location.href)).searchParams;
    const affiliateCode = params.get('a');
    return affiliateCode ? affiliateCode : null; 
  } else {
    return null;
  }
}

const FloatingArrow = () => {
  return (
    <a href="#buy" className="fixed sm:hidden bottom-4 right-4 w-10 h-10 bg-gradient-to-tr from-deepblue-400 to-deepblue-500 ring-2 border border-deepblue-500 text-white z-20 rounded-full flex items-center justify-center">
      <svg className="block w-[15px] h-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
    </a>
  );
}


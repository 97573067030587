import React from "react";
import { Link } from "gatsby";

import Layout from "../components/util/layout";
import SEO from "../components/util/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page Not Found — ADHD Pro" />
      <div className="text-center w-full mt-20">
        <h1 className="font-bold text-xl">
          This page does not exist.
        </h1>
        <p>
          Seriously I have no idea how you got yourself here.
        </p>

        <Link className="no-underline py-4" to="/">
          <div
            className={`block relative w-full pt-6`}
            >

            <div className="bg-transparent">
                <button
                  className="
                  
                  mx-auto
                  block
                  border
                  p-2
                  px-6
                  rounded-lg
                  text-white
                  border-deepblue-400
                  bg-deepblue-500
                  hover:bg-deepblue-600
                  focus:border-deepblue-400
                  focus:outline-none
                  ">
                    Go Home
                  </button>
            </div>

        </div>
      </Link>

      </div>
    </Layout>
  );
}

export default NotFoundPage;

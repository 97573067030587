import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

import PageWidth from '../util/pagewidth';
import Testimonial from '../util/testimonial';

// import Loadable from "@loadable/component"
// const EmbeddedVideo = Loadable(() => import("./embeddedvideo_hero"))


export default function PromoBody() {
  const pageQuery = useStaticQuery(graphql`
  query {
    controlledFocus: file(
      relativePath: { regex: "/controlledfocus.png/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 598
          traceSVG: { color: "rgb(2,48,153)" }
        ) {
          
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    hyperFocus: file(
      relativePath: { regex: "/hyperfocus.png/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 598
          traceSVG: { color: "rgb(2,48,153)" }
        ) {
          
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  
    profilePicture: file(
      relativePath: { regex: "/robert_merki.png/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 80
          traceSVG: { color: "rgb(2,48,153)" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
  
`);
  const profilePicture = pageQuery.profilePicture.childImageSharp.fluid;

  return (
  <div className="bg-blueGray-50 w-full border-t border-blueGray-100">
    <PageWidth>

        <article className="px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-16 lg:px-8 xl:max-w-6xl">
          <section className="prose sm:prose lg:prose-lg xl:prose-xl mx-auto relative z-10 ">
            {/* <h2>
              You Need a Strategy
            </h2>

            <EmbeddedVideo /> */}

            <h3>
              Are you constantly fighting your brain?
            </h3>

            <ul>
              <li>Trying to <strong>force yourself</strong> to <strong>get started</strong> on everyday work?</li>
              <li>Powerful bursts of <strong>hyperfocus</strong> that you can't seem to harness on command?</li>
              <li>Frustrating days of <strong>brainfog</strong> &amp; <strong>zero productivity</strong>?</li>
              <li>Feel like you're <strong>missing your potential?</strong></li>
            </ul>

            <h3>
              You need a Strategy
            </h3>  

            <div className="block sm:flex items-center">
              <Img fluid={profilePicture} loading={'eager'} alt="Robert Merki"
              className="ProfilePicture inline-block float-left h-16 w-16 rounded-full mr-4 shadow-sm  mt-0 mb-0"
              />
              <p>
                My name is Rob, and I spent my entire life struggling with ADHD.
                <br/>
                <strong>I tried everything they threw at me.</strong>
              </p>
            </div>


            <ul>
              <li>Vitamins &amp; Supplements</li>
              <li>Eliminating distractions</li>
              <li>Multiple new diets</li>
              <li>Dopamine fasting</li>
              <li>"Get a planner!"</li>
              <li>Cold showers</li>
              <li>"Discipline"</li>
              <li>Checklists</li>
              <li>Therapy</li>
            </ul>

            <p>
              Nothing was working because nobody really understood me.
            </p>

            <p>
              I wanted to get more productive and achieve my goals, and I didn't want constantly fight my own brain.
            </p>

            <p>
              After hundreds of hours of research &amp; interviews, I came up with a robust strategy for people with ADHD who wanted to get more done without feeling awful all the time.
            </p>

            <p>
              <strong>Start working with your brain instead of fighting it.</strong>
            </p>

            <Testimonial author="Dr. Adam Gorner" role="Resident Doctor" key="t2"> 
                {/* <p>
                ADHD Pro is an insightful, &amp; interesting book clearly written by someone with personal experience.
                I have had ADHD all my life and have been able to complete 3 university degrees including 2 doctorate degrees,
                and yet <strong>I still learned new strategies to manage my ADHD.</strong>
                </p> */}
                <p>
                ADHD Pro is an insightful &amp; interesting book clearly written by someone with personal experience.
                </p>
                <p>
                {/* <strong>I laughed out loud at a few parts of ADHD Pro. It was so accurate it was scary.</strong> */}
                <strong>It was so accurate it was scary.</strong>
                </p>
          </Testimonial>

          <Testimonial author="Oren Zohar" role="University Student" key="t3"> 
              <p>
                The book is <strong>witty</strong>, <strong>intellectual</strong>, <strong>extremely helpful</strong>, and is <strong>one of the first ADHD books that doesn't treat you like a child</strong>.
              </p>
          </Testimonial>



            <h3>
              It should be easy to do hard work.
            </h3>

            <p>
              You don't have to set smaller goals, "pace yourself", or be unhappy.
            </p>

            <p>
              And no, the solution isn't to "hustle" or "grind".
            </p>

            <p>
              <strong>You already work hard. Effort is not the problem.</strong>
            </p>

            <p>
              I wrote this book without a single bad day.
            </p>

            <p>
              I didn't grind or push myself, I just followed a good strategy every day.
            </p>
            
            <p>
              <strong>I learned to work <em>with</em> my brain instead of against it.</strong>
            </p>
        </section>
        </article>
      </PageWidth>
    </div>
  )
}

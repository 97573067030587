import { graphql, useStaticQuery, Link } from "gatsby";
// import { Link, graphql } from "gatsby";
import React, { useState } from "react";
import PageWidth from "./pagewidth";

function TopNav() {
  const [isOpen, toggleOpen] = useState(false);

  const toggleMenu = () => {
    toggleOpen(!isOpen);
  }


  return (
    <header className={`
    w-full
    bg-gradient-to-r from-deepblue-500 to-deepblue-700
    mb-6
    z-30
    `}>
    <PageWidth>
      <nav className="flex justify-between py-5 sm:py-5 items-baseline relative">
        <span className="font-bold text-xl text-white">
          <Link to="/" className="">ADHD <span className="text-goodyellow">Pro</span></Link>
        </span>
        <div className="">
          <Link to="/" className="tracking-tight font-semibold text-white hover:text-deepblue-200 p-4">Get the Book</Link>
          <span className="px-1 text-white opacity-50">•</span>
          <div className="relative overflow-visible inline-block group">
            <a
              //onClick={toggleMenu}
              className="tracking-tight font-semibold text-white hover:text-deepblue-200 p-4 cursor-pointer ">
              More <IconTriangle/>
            </a>
          <Menu/>
          </div>
        </div>
      </nav>
    </PageWidth>
  </header>
  );
}
export default TopNav;


const Menu = ({isOpen}) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              path
            }
          }
        }
      }
    }
  `);
  const posts = pageQuery.allMarkdownRemark.edges;



  // const animClasses = () => {
  //   if (isOpen) {
  //     return "visible opacity-100"
  //   }
  //   else {
  //     return "invisible opacity-0"
  //   }
  // };

  return (
      <div className={`
      invisible opacity-0 group-hover:visible group-hover:opacity-100
      transition-all transform-gpu absolute w-72 top-9 right-4 bg-white shadow-xl rounded-md p-4 border border-blueGray-100 text-black font-normal`}>
        
        <div className="pb-4">
          <div className="flex items-center mb-1">
            <span className="text-blueGray-300 mr-1">
              <IconFire/>
            </span>
            <span className="text-blueGray-500 font-bold tracking-loose text-sm">
              Top Posts
            </span>
          </div>
          <ul className="">
              {
              posts.map(({node}, index) => {
              if (index <= 2){
              return (
                  <li className="">
                    <Link
                    to={`/blog/${node.frontmatter.path}/`}
                    className="flex py-1 text-sm text-blueGray-600 hover:opacity-75 group" key={index}
                    >
                      <span className="mr-2 text-blueGray-400">&bull;</span>
                      <span className="group-hover:underline">
                        {node.frontmatter.title}
                      </span>
                    </Link>
                  </li>
              )}
            })}
            </ul>
            <Link
              to="/blog/"
              className="block py-1 mt-4 text-sm text-blueGray-400 group hover:underline"
            >
              Read the full blog
              <span className="ml-1 group-hover:ml-2 transition-all">
              <IconArrowRight/>
              </span>
            </Link>
        </div>

        <hr className="-mx-4 border-blueGray-200" />
        
        <Link className="flex items-center py-4 hover:underline" to="/newsletter/">
            <span className="text-blueGray-400 mr-1">
              <IconPaperClip/>
            </span>
            <span className="text-blueGray-500 font-bold tracking-loose text-sm">
              Sign-up for the Newsletter
            </span>
        </Link>

        <hr className="-mx-4 border-blueGray-200" />
        
        <Link className="flex items-center pt-4 hover:underline" to="/#buy">
            <span className="text-blueGray-400 mr-1">
              <IconBook/>
            </span>
            <span className="text-blueGray-500 font-bold tracking-loose text-sm ">
              Get the Book
            </span>
        </Link>

      </div>
  )
}



const IconTriangle = () => <svg className="inline-block w-[15px] h-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>;
const IconFire = () => <svg className="inline-block w-[15px] h-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clipRule="evenodd" /></svg>
const IconPaperClip = () => <svg className="inline-block w-[15px] h-[15px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" /></svg>
const IconBook = () => <svg className="inline-block w-[15px] h-[15px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" /></svg>
const IconArrowRight = () => <svg className="inline-block w-[13px] h-[13px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
</svg>;



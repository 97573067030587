import React from 'react'
import { Link } from "gatsby";

export default function BlogpostSuggestedArticle({pageContext}) {
  const { previous, next } = pageContext;

    if (next) {
      return (

          <div>
            <h2 className="text-xs tracking-wide uppercase text-blueGray-500">Next Article</h2>
            <Link className="text-md text-deepblue-400 hover:text-deepblue-500" to={`/blog/${next.frontmatter.path}/`}>{next.frontmatter.title}</Link>
          </div>

      )
    } else if (previous) {
      return(

        <div>
          <h2 className="text-xs tracking-wide uppercase text-blueGray-500">Previous Article</h2>
          <Link className="text-md text-deepblue-400 hover:text-deepblue-500" to={`/blog/${previous.frontmatter.path}/`}>{previous.frontmatter.title}</Link>
        </div>

      )
    } else {

      return <span></span>;

    }
}

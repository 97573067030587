import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/util/layout";
import SEO from "../components/util/seo";
import PageWidth from '../components/util/pagewidth';
import NewsletterForm from '../components/util/NewsletterForm';
import BlogpostHeader from '../components/blogpost/header';
import BlogpostFooter from '../components/blogpost/footer';
import BlogpostAuthor from '../components/blogpost/author';

function Blogpost({
  data, pageContext  // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, plainText } = markdownRemark
  const isAmp = pageContext.isAmp;
  return (
    <Layout>
      <SEO
        isAmp={isAmp}
        keywords={[`ADHD Pro`, `Productivity`, `ADHD`]}
        title={`${frontmatter.title}`}
        blogPostPath={frontmatter.path}
        description={frontmatter.description}
        frontmatter={frontmatter}
        postType={frontmatter.type}
        articleBody={plainText}
      />

      <PageWidth>
      
      <article className="xl:divide-y xl:divide-blueGray-200">

        <BlogpostHeader frontmatter={frontmatter} />

        <div className="divide-y xl:divide-y-0 divide-blueGray-200 xl:grid xl:grid-cols-4 xl:gap-x-6 pb-16 xl:pb-20 relative"
          style={{gridTemplateRows: "auto 1fr"}}
        >

          <BlogpostAuthor isAmp={isAmp} />
          
          <div className="blog-post mr-0 max-w-none pt-10 sm:pt-5 prose sm:prose lg:prose-lg xl:prose-xl
                          divide-y divide-none xl:pb-0 xl:col-span-3 xl:row-span-2">
            <div
                className=""
                dangerouslySetInnerHTML={{ __html: html }}
            />
              
              <NewsletterForm isAmp={isAmp} />

          </div>
          
          

          <BlogpostFooter pageContext={pageContext} />


        </div>
      </article>
      </PageWidth>
    </Layout>
  )
}

export default Blogpost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      plainText
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        path
        type
      }
    }
  }
`
import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

export default function BlogpostBookPromo({isAmp}) {

  const pageQuery = useStaticQuery(graphql`
    query {
      bookCover: file(
        relativePath: { regex: "/book-3d.png/" }
      ) {
        childImageSharp {
          fixed(
            width: 200
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const bookCover = pageQuery.bookCover.childImageSharp;

  const BookCoverImage = () => {
    if (isAmp) {
      return <amp-img 
        // src-set={bookCover.fixed.srcSet}
        src={bookCover.fixed.src}
        width={bookCover.fixed.width}
        height={bookCover.fixed.height}
        alt="ADHD Pro Cover"
        class="mx-auto max-w-[200px] block"
          />
    } else {
      return <div className="text-center"><Img fixed={bookCover.fixed}
      loading="eagar"
      alt="ADHD Pro Cover"
      className="mx-auto max-w-[200px] block"
    /></div>
    }
  }

  return (
    <Link to="/#buy" className="mx-auto block">


      <BookCoverImage/>


      <div className="text-center mt-4">
        <span className="block">Get more productive with <strong>ADHD Pro</strong></span>
        <span className="block underline text-blue-600">Learn More</span>
      </div>
    </Link>
  )
}

import React from 'react'
import PageWidth from "../util/pagewidth";
import NiceUnderline from "../util/niceunderline";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import Testimonial from '../util/testimonial';
import BuyButtons from "./buybuttons";

export default function Hero() {
  
  const pageQuery = useStaticQuery(graphql`
  query {
    bookCover: file(
      relativePath: { regex: "/book-3d.png/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 600
          traceSVG: { color: "rgb(2,48,153)" }
        ) {
          
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`);

  const bookCover = pageQuery.bookCover.childImageSharp.fluid;

  return (
    <div className="w-full bookbg bg-white pt-16 pb-4 overflow-hidden relative" id="top">
    <PageWidth>
      <section className="py-0 md:py-8 pb-0">
        
        <div className="w-full block md:flex md:items-center">
          <div className="text-left pb-4">      
              <h1 className="block w-full text-4xl md:text-5xl font-bold leading-none text-deepblue-400 mb-4">
                Sustainable Productivity for People with ADHD
              </h1>
              <h2 className="block w-full text-2xl md:text-2xl font-medium leading-tight text-blueGray-700 mb-1">
                The ADHD book written by someone with ADHD <span className="text-deepblue-500 font-cursive">(me)</span> who <em>actually</em> understands how your brain works <span className="text-deepblue-500 font-cursive">(because mine works the same way)</span>.
              </h2>
            <div className="w-auto">
              <h3 className="text-blueGray-600 w-auto py-4 text-md sm:text-lg mb-4">
                Stop fighting your brain. Learn how it works instead.
              </h3>

              <BuyButtons center={false} clickId="Hero" />
              {/* <a className="
                  text-center
                  border
                  border-transparent
                  text-lg
                  shadow-md
                  mx-auto
                  sm:mx-0
                  focus:outline-none
                  text-white
                  text-shadow
                  uppercase
                  tracking-wide
                  font-semibold
                  px-6
                  py-3
                  bg-green-600
                  hover:bg-green-500
                  block
                  md:inline-block
                  rounded" href="#buy">
                  Get the book
              </a> */}
              {/* <a href="/sample-chapter/" className="block mt-4 underline text-blueGray-700 text-center sm:text-left">(Or read the sample chapter first)</a> */}
            </div>
          </div>
            <figure className="h-auto w-1/2 mx-auto sm:w-1/2 md:w-3/8 lg:w-3/12 mt-4 sm:mt-0 flex-shrink-0">
            <a 
              className="block w-full mx-auto"
              href="https://www.amazon.com/dp/B096LS2PYS/"
              >
              <div className="">
                <Img
                  alt="ADHD Pro by Robert Merki"
                  fluid={bookCover}
                  loading={'eager'} //https://www.gatsbyjs.com/plugins/gatsby-image/#gatsby-image-props
                  />
              </div>
            </a>
            <FiveStars/>
          </figure>
        </div>


        <div className="flex items-center justify-center">
          <span className="text-gray-600 font-bold mr-1">
            More info
          </span>
          <DownArrow />
        </div>


        <div>
          <Testimonial author="Snorris" role="Review on Amazon.com" key="t1"> 
            <p>
              I have ADHD and this book has been life changing.
            </p>
            <br/>
            <p>
              <strong>I have recommended this book to everyone in my life who also has ADHD and now I recommend it to you too, stranger.</strong>
            </p>
          </Testimonial>

          <Testimonial author="Sukhans" role="Software Engineer at Microsoft" key="t2"> 
            <p>
              <strong>ADHD Pro is essential reading if you plan on succeeding with ADHD in your professional life.</strong>
            </p>
          </Testimonial>

          <Testimonial author="Chris" role="Review on Goodreads" key="t3"> 
            <p>
              <strong>This is a really well-written and useful book.</strong>
              <br/>
              <br/>
              There is a lot of eloquently described pain and resentment that the author feels having had to deal
              with many of these issues alone and without adequate support.
              <br/>
              <br/>
              <strong>The strategies in the book are helpful and well-thought out.</strong> They have clearly been devised from a combination of intelligent reflection,
              trial and error and extensive research. 
            </p>
            
          </Testimonial>
          <Testimonial author="Zeljko" role="Review on Amazon.com" key="t4"> 
            <p>
              Others said it, this is the best. Excellent balance of information with minimal pushing, written by a person who knows his audience perfectly.
            </p>
            <br/>
            <p>
              For me, I recently decided to finally get to the bottom of it, found some youtube videos, a ton of books that all seemed good but ultimately didn't deliver as much. This one is different and it is life changing. You really have to read it to understand. Strategies or suggestions are on point and flexible enough for everyone to be able to adapt and use.
            </p>
            <br/>
            <p>
              <strong>
                I plan to read this at least few more time, this book speaks to me unlike any other.
              </strong>
            </p>
          </Testimonial>

          <Testimonial author="CW" role="Review on Amazon.com" key="t5"> 
            <p>
              You can tell that the author is someone who has really learned through their own experiences.
            </p>
            <br/>
            <p>
              <strong>The book is very clear, and I picked up a number of valuable insights as to how to not only cope with, but how to thrive with, ADHD.</strong>
            </p>
          </Testimonial>
          
          <div className="text-center">
            <a className="mx-auto underline text-blue-700" href="#testimonials">Read more reviews &rarr;</a>
          </div>

        </div>
  
      
      </section>
    </PageWidth>
  </div>
  )
}

const FiveStars = () => (
  <div className="mb-4 -mt-3">
    <div className="text-center text-goodyellow">
      <span className="mr-3"></span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span>&#9733;</span>
      <span className="text-sm ml-1 text-yellow-500">(55)</span>
    </div>
    <div className="text-center text-sm">
      Rated <strong>4.98</strong> on GoodReads &amp; Amazon
    </div>
    {/* <div className="text-sm leading-none mt-2 text-center text-blueGray-900 font-cursive">* Seriously I can't believe it. Talk about imposter syndrome.</div> */}
  </div>
  );


  const DownArrow = () => <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
</svg>
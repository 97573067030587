import React from 'react'

export default function BuyButtons({center=true, clickId="Unset"}) {
  return (
<div className={`w-full sm:flex ${center ? 'justify-center' : 'justify-start'} mt-6 space-y-3 sm:space-y-0 sm:space-x-3 mb-20 items-start`}>
        <div className="text-center">
          <a className="
                    text-center
                    border
                    border-transparent
                    text-lg
                    shadow-md
                    
                    sm:mx-0
                    focus:outline-none
                    
                    text-shadow
                    tracking-wide
                    font-semibold
                    px-6
                    pt-3
                    pb-2
                    bg-blue-600
                    hover:bg-blue-500
                    text-white                    
                    block
                    
                    rounded"
                    id={`BuyOnAmazon_${clickId}`}                    
                    href="https://geni.us/kcGGAX">
                    Buy on Amazon
          </a>
          <span className="text-sm font-bold text-green-600">Kindle &amp; physical copy</span>

        </div>
        <div className="text-center">
          <a className="
                    text-center
                    border
                    border-transparent
                    text-lg
                    shadow-md
                    
                    sm:mx-0
                    focus:outline-none
                    text-white
                    text-shadow
                    tracking-wide
                    font-semibold
                    px-6
                    pt-3
                    pb-2
                    bg-orange-600
                    hover:bg-orange-500
                    block
                    rounded"
                    id={`BuyOnAudible_${clickId}`}
                    href={`https://www.audible.com/pd/B096YM2675/?source_code=AUDFPWS0223189MWT-BK-ACX0-262375&ref=acx_bty_BK_ACX0_262375_rh_us`} 
                    >
                    Get the Audiobook
          </a>
          <span className="text-sm font-bold text-green-600">Narrated by me</span>
        </div>

        <div className="text-center">
          <a className="
                    text-center
                    border
                    border-transparent
                    text-lg
                    shadow-md
                    
                    sm:mx-0
                    focus:outline-none
                    text-white
                    text-shadow
                    tracking-wide
                    font-semibold
                    px-6
                    pt-3
                    pb-2
                    bg-indigo-600
                    hover:bg-indigo-500
                    block
                    rounded"
                    id={`BuyOnGumroad_${clickId}`}
                    href={`https://gumroad.com/robmerki`} 
                    >
                    Get the digital version
          </a>
          <span className="text-sm font-bold text-green-600">Get the PDF directly</span>
        </div>

      </div>
  )
}

import React from 'react'

export default function BlogPostHeader({frontmatter}) {
  return (
    <header className="
    blog-meta 
    pb-6 xl:pb-12
    text-center
    ">

      <dl className="space-y-10">
        <dt className="sr-only">Published on</dt>
        <dd className="text-base leading-6 font-medium text-blueGray-500">
          <time>{frontmatter.date}</time>
        </dd>
      </dl>

      <h1 className="text-3xl leading-9 font-extrabold text-blueGray-900 tracking-tight sm:text-4xl sm:leading-10 md:text-5xl md:leading-14">
        {frontmatter.title}
      </h1>

    </header>
  )
}

import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import PageWidth from '../util/pagewidth';
import SectionTitle from '../util/sectiontitle';
import SampleChapters from '../util/SampleChapters';


export default function BookPreview({affiliateCode}) {

  const pageQuery = useStaticQuery(graphql`
  query {
    devices: file(
      relativePath: { regex: "/Devices.png/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1402
          traceSVG: { color: "rgb(35,37,38)" }
        ) {
          
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
  
`);
  const devices = pageQuery.devices.childImageSharp.fluid;

  return (
    <div className="bg-white w-full py-16">
      <PageWidth>
        <div className="">
        
          <div className="mb-12 px-4 py-10 max-w-3xl mx-auto sm:px-6 sm:py-12 lg:max-w-4xl lg:py-8 lg:px-8 xl:max-w-6xl">
            <article className="prose sm:prose lg:prose-lg xl:prose-xl  mx-auto">
              <SectionTitle className="">Achieve more goals without the constant pain.</SectionTitle>
              {/* <p className="">
                ADHD Pro has real strategies to help you <strong></strong>
              </p> */}
            </article>
          </div>

          <div className="flex flex-wrap text-center justify-center">

            <div className="w-9/12 sm:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="flex justify-center text-deepblue-400">
                <IconCap />
              </div>
              <div className="font-black text-lg mt-1 mb-1">Real Symptoms, No B.S.</div>
              <div className="text-md">
                ADHD isn't just lack of focus.
                It's a whole range of dopamine receptor issues.
                Learn how these <em>really</em> affect your work.
              </div>              
            </div>

            <div className="w-9/12 sm:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="flex justify-center text-deepblue-400">
                <IconAlert />
              </div>
              <div className="font-black text-lg mt-1 mb-1">Harsh Truths</div>
              <div className="text-md">
                Your symptoms are real and so are the consequences.
                Learn how to deal with this mentality, and how to gain control.
              </div>              
            </div>

            <div className="w-9/12 sm:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="flex justify-center text-deepblue-400">
                <IconPuzzle />
              </div>
              <div className="font-black text-lg mt-1 mb-1">A New Mindset</div>
              <div className="text-md">
                Your mindset needs to change.
                <br/>
                Get to work with a positive, sustainable attitude.
              </div>              
            </div>

            <div className="w-9/12 sm:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="flex justify-center text-deepblue-400">
                <IconUptrend />
              </div>
              <div className="font-black text-lg mt-1 mb-1">Real Strategy</div>
              <div className="text-md">
                Working hard should be easy.
                <br/>
                Learn to build a better strategy one component at a time.
              </div>              
            </div>

          <div className="w-9/12 sm:w-1/2 md:w-1/3 px-4 mb-8">
            <div className="flex justify-center text-deepblue-400">
              <IconVariable />
            </div>
            <div className="font-black text-lg mt-1 mb-1">Eliminate Distractions</div>
            <div className="text-md">
              Don't block websites or delete apps.
              <br/>
              Deal with distractions the real way: by being focused on your work.
            </div>              
          </div>

            <div className="w-9/12 sm:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="flex justify-center text-deepblue-400">
                <IconSparkles />
              </div>
              <div className="font-black text-lg mt-1 mb-1">Full Examples</div>
              <div className="text-md">
                Understand how to fully implement your own strategy.
                Read the walkthrough and start your journey.
              </div>              
            </div>
          </div>

          <div className="flex flex-wrap md:flex-nowrap justify-center items-center mt-4">   
            <div className="w-full md:max-w-md flex flex-wrap">
              <div className="w-full h-auto">
                <Img className="w-full" fluid={devices} alt="The ADHD Pro book, shown on some compatible devices such as the Kindle and iPad Pro" />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-center mt-2">
                
                {/* <a className="block text-xl font-black mb-4 text-deepblue-500 underline w-full text-center" href="/sample-chapter/">Read the Sample Chapter</a> */}
                {/* <SampleChapters /> */}
                <span>Audiobook, Kindle edition, &amp; print version <a href="#buy" className="text-blue-500 underline">now available!</a></span>

          </div>

        </div>
      </PageWidth>
    </div>
  )
}


const IconCap = () => <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z" /><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" /></svg>;
const IconPuzzle = () => <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" /></svg>;
const IconAlert = () => <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>;
const IconUptrend = () => <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" /></svg>;
const IconVariable = () => <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.871 4A17.926 17.926 0 003 12c0 2.874.673 5.59 1.871 8m14.13 0a17.926 17.926 0 001.87-8c0-2.874-.673-5.59-1.87-8M9 9h1.246a1 1 0 01.961.725l1.586 5.55a1 1 0 00.961.725H15m1-7h-.08a2 2 0 00-1.519.698L9.6 15.302A2 2 0 018.08 16H8" /></svg>;
const IconSparkles = () => <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" /></svg>;
import PropTypes from "prop-types";
import React from "react";

function PageWidth({ children }) {
  return (
    <div className="w-full relative">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 xl:max-w-5xl xl:px-0">
          {children}
      </div>
    </div>
  );
}

PageWidth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWidth;

import React from 'react'
import PageWidth from '../util/pagewidth';

const content = [
  {
    q: "What if I'm not sure if I have ADHD?",
    a: "Even if you aren't diagnosed with ADHD, you can learn a TON about how to build a more productive life. That being said, the majority of the content in ADHD Pro is directed towards those who have ADHD."
  },
  {
    q: "A book? For people with ADHD?! I can't pay attention to a book!",
    a: "Give it a shot. Better yet, listen to the audiobook, narrated by me :)"
  },
  // {
  //   q: "What is your background? Are you a therapist?",
  //   a: "My background is in tech startups, and I have no background in therapy/psychiatry/etc. There are plenty of excellent books written by therapists and psychologists, but I think my lack of formal training is an advantage for this particular issue."
  // },
]

export default function Faq() {
  return (
    <div className="w-full bg-blueGray-800 z-30 relative">
      <PageWidth>
        <section className="text-xl text-blueGray-800 py-20 sm:px-10">
          <h2 className="font-bold text-4xl text-white leading-none mb-2 text-center w-full sm:w-2/3 mx-auto mb-8">
            Frequently Asked Questions
          </h2>
          {content.map(
            (question,i)=>
              <div key={i} className="lg:flex lg:flex-wrap lg:items-start py-8 text-xl border-t border-blueGray-500">
                <div className="lg:w-1/3 lg:pr-6 font-bold text-white mb-4 lg:mb-0">
                  {question.q}
                </div>
                <div className="lg:w-2/3 lg:pl-6 text-blueGray-300 leading-normal">
                  {question.a}
                </div>
              </div>
            )
          }
   

        </section>
      </PageWidth>
    </div>
  )
}

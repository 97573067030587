import React from 'react'
import { Link } from "gatsby";

const CONVERTKIT_FORM_ID = "1874096";


export default function NewsletterForm({noDescription, noLine, isAmp}) {

  if (isAmp) {
    return (
      <Link className="no-underline py-4" to="/newsletter">
        <div
          className={` block relative w-full ${noLine ? '' : 'border-t'} pt-6`}
          >
          {!noDescription && <div className=" font-black text-center">
            Want more like this?
          </div>}
          <div className="bg-transparent">
              <button
                className="
                
                mx-auto
                block
                border
                p-2
                px-6
                rounded-lg
                text-white
                border-deepblue-400
                bg-deepblue-500
                hover:bg-deepblue-600
                focus:border-deepblue-400
                focus:outline-none
                ">
                  Join the Newsletter
                </button>
          </div>
          <div className=" formkit-disclaimer text-xs text-blueGray-400 w-full text-center mt-1">
            Sent a few times per month. No spam ever.
          </div>
      </div>
      </Link>
    )
  } else {
  
    return (
      <div className="py-4">
        <form
          className={`block relative w-full ${noLine ? '' : 'border-t'} pt-6`}
          method="post"
          action={`https://app.convertkit.com/forms/${CONVERTKIT_FORM_ID}/subscriptions`}
          data-sv-form={CONVERTKIT_FORM_ID}
          method="post"
          data-uid="ad70034712"
          data-format="inline"
          data-version="5"
          data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;redirect&quot;,&quot;success_message&quot;:&quot;Success!
            Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;https://adhdpro.xyz/newsletter-confirm&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}"

          >
          {!noDescription && <div className="font-black text-center">
            Want more like this? Join the newsletter:
          </div>}
          <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
          <div className="bg-transparent">
            <div className="max-w-full sm:max-w-md md:max-w-lg sm:flex sm:overflow-hidden mx-auto bg-transparent shadow-none sm:shadow-xl rounded-lg">
              <input type="email" name="email_address" required placeholder="Enter your email"
                className="
                shadow-xl
                sm:shadow-none
                block
                w-full
                rounded-lg
                border-deepblue-200
                focus:border-deepblue-400
                border
                border-transparent
                sm:rounded-r-none
                mb-4
                sm:mb-0
                text-black
                sm:flex-1
                px-6
                py-2
                
                focus:outline-none" />
              <button
                data-element="submit"
                className="
                mx-auto
                block
                border
                p-2
                px-6
                rounded-lg
                sm:rounded-l-none
                text-white
                border-deepblue-400
                bg-deepblue-500
                hover:bg-deepblue-600
                focus:border-deepblue-400
                focus:outline-none
                ">
                  Join Now
                </button>
            </div>
          </div>
          <div className="formkit-disclaimer text-xs text-blueGray-400 w-full text-center mt-1" data-element="disclaimer">
            Sent a few times per month. No spam ever.
          </div>
          <div id="ck_error_msg" className="sm:absolute pin-x text-center md:text-left text-indigo-dark font-semibold mt-6 text-lg" style={{'display': 'none'}}>
            <p>Something went wrong, please try again.</p>
          </div>
      </form>
    </div>
    )
  }
}
import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"

export default function BlogpostAuthor({isAmp}) {
  
    const pageQuery = useStaticQuery(graphql`
    query {
      profilePictureData: file(
        relativePath: { regex: "/robert_merki.png/" }
      ) {
        childImageSharp {
          fixed(
            width: 40
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const profilePicture = pageQuery.profilePictureData.childImageSharp;

  const ProfilePictureImage = () => {
    
    if (isAmp) {
      return <amp-img 
        // src-set={profilePicture.fixed.srcSet}
        src={profilePicture.fixed.src}
        width={profilePicture.fixed.width}
        height={profilePicture.fixed.height}
        alt="Robert Merki"
        class="w-[40px] h-[40px] rounded-full"
          />
    } else {
      return <Img  fixed={profilePicture.fixed}
      loading="eagar"
      alt="Robert Merki"
      className="w-[40px] h-[40px] rounded-full"
    />
    }
  }


  return (
    <dl className="pt-0 pb-10 xl:pt-10 xl:border-b xl:border-blueGray-200">
    <dt className="sr-only">Authors</dt>
    <dd>
      <div className="flex justify-center xl:block space-x-8 sm:space-x-12 xl:space-x-0 xl:space-y-8">
        <div className="flex items-center space-x-2 ">
        
          <ProfilePictureImage/>

          <dl className="text-sm font-medium leading-5 whitespace-nowrap">
            <dt className="sr-only">Name</dt>
            <dd>Robert Merki</dd>
            <dt className="sr-only">Twitter</dt>
            <dd>
              <a href="https://twitter.com/robmerki" className="text-deepblue-500">@robmerki</a>
            </dd>
          </dl>
        </div>
      </div>
    </dd>
  </dl>
  )
}


import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../../components/util/seo";

export default function Links({ urlref = "links_raw" }) {
  const [copiedLink, setCopiedLink] = useState("");

  const pageQuery = useStaticQuery(graphql`
    query {
      profilePicture: file(relativePath: { regex: "/robert_merki_new.png/" }) {
        childImageSharp {
          fluid(maxWidth: 60, traceSVG: { color: "rgb(2,48,153)" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      bookCover: file(relativePath: { regex: "/book-3d-crop.png/" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wildcardPeople: file(relativePath: { regex: "/wildcardpeople.png/" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const profilePicture = pageQuery.profilePicture.childImageSharp.fluid;
  const bookCover = pageQuery.bookCover.childImageSharp;
  const wildcardPeople = pageQuery.wildcardPeople.childImageSharp;

  const LinkBaseClass =
    "bg-white shadow-sm flex items-center block py-4 px-3 sm:px-3 border sm:border-l sm:border-r sm:rounded-md hover:bg-blue-100 hover:border-blue-300 transition-all" +
    " ";

  function copyLink(txt) {
    setCopiedLink(txt);
    console.log(txt);
    var m = document;
    txt = m.createTextNode(txt);
    var w = window;
    var b = m.body;
    b.appendChild(txt);
    if (b.createTextRange) {
      var d = b.createTextRange();
      d.moveToElementText(txt);
      d.select();
      m.execCommand("copy");
    } else {
      var d = m.createRange();
      var g = w.getSelection;
      d.selectNodeContents(txt);
      g().removeAllRanges();
      g().addRange(d);
      m.execCommand("copy");
      g().removeAllRanges();
    }

    txt.remove();
  }

  return (
    <div className="min-h-screen w-full flex flex-wrap justify-center pt-6 sm:pt-20 bg-gray-50">
      <SEO
        title="ADHD Pro - Links"
        keywords={[
          `ADHD Pro`,
          `Adult ADHD Book`,
          `Happy And Sustainable Productivity For People With ADHD`,
          `Take control of your ADHD`,
        ]}
      />

      <div className="sm:max-w-lg w-full">
        <div>
          <div className="flex justify-center">
            <Img
              fluid={profilePicture}
              loading={"eager"}
              alt="Robert Merki"
              className="h-[60px] w-[60px] rounded-full shadow-sm mb-3"
            />
          </div>
          <h1 className="text-center mb-4 font-bold text-md text-blueGray-600">
            Links to My Stuff
          </h1>
        </div>

        <div className="">
          <div className="grid grid-cols-1 gap-8">
            {/* <div className="shadow-sm sm:rounded">
              <a
                className={LinkBaseClass + ""}
                id="Links_WildcardPeople"
                href={`https://wildcardpeople.com/?ref=${urlref}`}
              >
                <div className="flex items-center w-16 sm:w-20 flex-shrink-0 justify-center">
                  <Img
                    fluid={wildcardPeople.fluid}
                    loading="eager"
                    alt="Wildcard People logo"
                    className="block h-auto w-full mr-3 sm:mr-4"
                  />
                </div>
                <div>
                  <div className="font-bold text-xl text-blueGray-800 flex items-center">
                    <h2>Wildcard People</h2>
                    <span className="ml-2 text-sm bg-blue-200 text-blue-800 px-2 rounded-full no-underline">
                      New
                    </span>
                  </div>
                  <h3 className="text-sm sm:text-md leading-tight mb-2">
                    Jobs for neurodiverse / swiss-army knife people.
                  </h3>
                  <div className="text-sm text-blueGray-500">
                    <span className="">Relaunched for 2023!</span>
                  </div>
                </div>
                <RightArrowIcon />
              </a>
            </div> */}

            <div className="shadow-sm sm:rounded">
              <Link
                className={LinkBaseClass + "bg-white"}
                id="Links_AdhdPro"
                to={`/?ref=${urlref}`}
              >
                <div className="flex items-center w-16 sm:w-20 flex-shrink-0 justify-center">
                  <Img
                    fluid={bookCover.fluid}
                    loading="eager"
                    alt="ADHD Pro Cover"
                    className="block h-auto w-full mr-3 sm:mr-4"
                  />
                </div>
                <div>
                  <h2 className="font-bold text-xl">ADHD Pro</h2>
                  <h3 className="text-sm sm:text-md leading-tight mb-2">
                    My book about ADHD productivity for professionals
                  </h3>
                  <div className="text-goodyellow leading-none">
                    <span className="leading-none">★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span className="text-sm ml-1 text-yellow-600 font-bold">
                      (72)
                    </span>
                    <span className="hidden sm:block text-sm text-blueGray-600 leading-none">
                      Rated <strong>4.76</strong> on Goodreads &amp; Amazon
                    </span>
                  </div>
                </div>
                <RightArrowIcon />
              </Link>
            </div>

            <hr className="border-t my-2" />

            <div className="border flex flex-col sm:rounded overflow-hidden divide-y divide-gray-200">
              <SmallLink href={"https://www.tiktok.com/@adhdpro.xyz"}>
                <div className="flex items-center space-x-2">
                  <div className="w-8">
                    <TiktokLogo />
                  </div>
                  <span>TikTok</span>
                </div>
              </SmallLink>
              <SmallLink href={"https://www.youtube.com/@adhdproxyz"}>
                <div className="flex items-center space-x-2">
                  <div className="w-8">
                    <YoutubeLogo />
                  </div>
                  <span>YouTube</span>
                </div>
              </SmallLink>
              <SmallLink href={"https://instagram.com/adhdpro.xyz"}>
                <div className="flex items-center space-x-2">
                  <div className="w-8">
                    <InstagramLogo />
                  </div>
                  <span>Instagram</span>
                </div>
              </SmallLink>
              {/* <SmallLink href={"https://twitter.com/adhdproxyz"}>
                <div className="flex items-center space-x-2">
                  <div className="w-8">
                    <TwitterLogo />
                  </div>
                  <span>Twitter</span>
                </div>
              </SmallLink> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SmallLink = ({ children, href }) => {
  return (
    <a
      className="bg-white flex justify-between shadow-sm py-4 px-3 sm:px-3 font-semibold hover:bg-blue-100 hover:border-blue-400"
      href={href}
    >
      <div className="flex-grow-1">{children}</div>
      <RightArrowIcon />
    </a>
  );
};

const RightArrowIcon = () => (
  <div className="ml-auto">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-blueGray-400 h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

const TiktokLogo = () => (
  <svg
    className="text-black h-5 fill-current"
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
  </svg>
);
const TwitterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="text-blue-400 h-5 fill-current"
    viewBox="0 0 24 24"
  >
    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
  </svg>
);
const YoutubeLogo = () => (
  <svg
    className="text-red-500 h-5 fill-current"
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>YouTube</title>
    <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
  </svg>
);
const InstagramLogo = () => (
  <svg
    className="text-indigo-400 h-5 fill-current"
    role="img"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Instagram</title>
    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
  </svg>
);
